import { navigate } from 'gatsby';
import React from 'react';

import S from './style';


const NotFound: React.FC = () => (
  <S.wrap>
    <S.subTitle>
      404 ERROR!
    </S.subTitle>
    <S.title>
      길을 잃었다. 어딜 가야 할까...
    </S.title>
    <S.contents>
      요청하신 페이지를 찾을 수 없습니다.
      <br />
      입력하신 주소가 정확한지 다시 확인해주세요.
    </S.contents>
    <S.button
      onClick={() => { navigate('/'); }}
    >
      메인으로
    </S.button>
  </S.wrap>
);

export default NotFound;
