import React from "react";

import Head from "../components/Head";
import ResetCss from "../components/resetCSS";
import NotFound from "../containers/NotFound";

const NotFoundPage = () => (
  <>
    <ResetCss />
    <Head title="404: Not Found" />
    <NotFound />
  </>
);
export default NotFoundPage;
