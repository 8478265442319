import styled from "styled-components"
import { themeGet } from "styled-system"

const S = {
  wrap: styled.div`
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(125deg, #00e5c1, #00c68e);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  subTitle: styled.p`
    opacity: 0.7;
    font-size: ${themeGet('fontSizes.5' ,'16')}px;
    font-weight: ${themeGet('fontWeights.6', '700')};
    line-height: 1.5;
    color: ${themeGet('colors.white', '#fff')};
    margin-bottom: 13px;
    
    @media (max-width: 576px) {
      font-size: ${themeGet('fontSizes.1' ,'12')}px;
      margin-bottom: 10px;
    }
    
    @media (max-width: 320px) {
      font-size: ${themeGet('fontSizes.0' ,'10')}px;
    }
  `,
  title: styled.h1`
    font-size: ${themeGet('fontSizes.12' ,'40')}px;
    font-weight: ${themeGet('fontWeights.6', '700')};
    line-height: 1.4;
    letter-spacing: -1px;
    color: ${themeGet('colors.white', '#fff')};
    margin-bottom: 28px;
    
    @media (max-width: 576px) {
      font-size: ${themeGet('fontSizes.6' ,'24')}px;
      line-height: 1.33;
      letter-spacing: -0.8px;
      margin-bottom: 10px;
    }
    
    @media (max-width: 320px) {
      font-size: ${themeGet('fontSizes.2' ,'14')}px;
    }
  `,
  contents: styled.p`
    font-size: ${themeGet('fontSizes.6' ,'24')}px;
    font-weight: ${themeGet('fontWeights.4', '500')};
    line-height: 1.67;
    letter-spacing: -0.67px;
    text-align: center;   
    color: ${themeGet('colors.white', '#fff')}; 
    margin-bottom: 64px;
    
    @media (max-width: 576px) {
      font-size: ${themeGet('fontSizes.4' ,'16')}px;
      opacity: 0.7;
      line-height: 1.5;
      letter-spacing: -0.44px;
      margin-bottom: 40px;
    }
    
    @media (max-width: 320px) {
      font-size: ${themeGet('fontSizes.2' ,'14')}px;
    }
  `,
  button: styled.button`
    width: 200px;
    line-height: 60px;
    border: solid 1px ${themeGet('colors.white', '#fff')}; 
    border-radius: 30px;
    font-size: ${themeGet('fontSizes.5' ,'18')}px;
    font-weight: ${themeGet('fontWeights.6', '700')};
    letter-spacing: 1px;
    color: ${themeGet('colors.white', '#fff')};
    background: ${themeGet('colors.transparent', 'transparent')};
    transition: all ease 250ms;
    
    &:focus {
      outline: none; 
    }
    &:hover {
      outline: none;
      background: ${themeGet('colors.white', '#fff')};
      color: ${themeGet('colors.primary', '#00c68e')};
    } 
     
    @media (max-width: 576px) {
      width: 140px;
      line-height: 48px;
      font-size: ${themeGet('fontSizes.2' ,'14')}px;
      letter-spacing: 0.78px;
    }
    
    @media (max-width: 320px) {
      font-size: ${themeGet('fontSizes.2' ,'14')}px;
    }
  `,
};

export default S;
